/** @format */

import React from "react";

import { sPythonIcon } from "./../extension/skillsImgs";

import "./../style/skills.css";

export const TwelvethSkill = () => {
  const handleGithubClick = () => {
    window.open("https://github.com/Abu-Nabe/PythonEmailExtractor", "_blank");
  };

  return (
    <div>
      <div className="skillsMainDiv">
        <img className="skillsImg" src={sPythonIcon} alt="Image" />
        <p class="skillsName2">
          <span onClick={handleGithubClick}>Github</span>
        </p>
        <p class="skillsName2">Languages: Python</p>
        <p class="skillsName2">Purpose: To automate email marketing</p>
        <p class="skillsName2">Frameworks: Visual Studio Code</p>

      </div>
    </div>
  );
};

export default TwelvethSkill;
