/** @format */

import React from "react";

import { sTypeScriptIcon } from "./../extension/skillsImgs";

import "./../style/skills.css";

export const EleventhSkill = () => {

  const handleGithubClick = () => {
    window.open("https://github.com/Abu-Nabe/Personal-AI-Chat-Bot", "_blank");
  };

  return (
    <div>
      <div className="skillsMainDiv">
        <img className="skillsImg" src={sTypeScriptIcon} alt="Image" />
        <p class="skillsName2">
          <span onClick={handleGithubClick}>Availability: Github</span>
        </p>
        <p class="skillsName2">Languages: TypeScript</p>
        <p class="skillsName2">Purpose: To deepen my expertise in frontend development and modern tech stacks.</p>
        <p class="skillsName2">Frameworks: Next JS</p>
      </div>
    </div>
  );
};

export default EleventhSkill;
