/** @format */

import React, { useEffect, useState } from "react";

import { fsMobileIcon, fsWebIcon, sKotlinIcon, sReactIcon, sFlutterIcon, sJavaIcon, sSwiftIcon, sFirebaseIcon, sCsharpIcon, sAlgorithmIcon, sTypeScriptIcon} from "./../extension/skillsImgs";

export const MainMediaSkill = () => {
  
  return (
    <div className="skillsDiv">
        <div className="skillsMediaDiv">
        <p class="skillsMediaName">Fullstack Mobile Application</p>
        <img className="skillsImg" src={fsMobileIcon} alt="Image" />
             <p class="skillsMediaName2">Languages: Java, Swift, PHP, JavaScript</p>
          <p class="skillsMediaName2">Database Management: NodeJS, MySql & Cloudways</p>
          <p class="skillsMediaName2">Frameworks: Android Studio & Xcode</p>
        </div>

        <div className="skillsMediaDiv">
        <p class="skillsMediaName">Fullstack Web Application</p>
        <img className="skillsImg" src={fsWebIcon} alt="Image" />
            <p class="skillsMediaName2">Languages: JavaScript, PHP</p>
            <p class="skillsMediaName2">Database Management: NodeJS, MySql & Amazon Web Services</p>
            <p class="skillsMediaName2">Frameworks: Visual Studio Code</p>
        </div>

        <div className="skillsMediaDiv">
        <p class="skillsMediaName">TypeScript: AI Chat Bot</p>
        <img className="skillsImg" src={sTypeScriptIcon} alt="Image" />
            <p class="skillsMediaName2">Languages: TypeScript</p>
            <p class="skillsMediaName2">Purpose: To deepen my expertise in frontend development and modern tech stacks.</p>
            <p class="skillsMediaName2">Frameworks: Next JS</p>
        </div>

        <div className="skillsMediaDiv">
        <p class="skillsMediaName">Kotlin: University Assignment</p>
        <img className="skillsImg" src={sKotlinIcon} alt="Image" />
            <p class="skillsMediaName2">Purpose: Assisting friend with his university assignment</p>
            <p class="skillsMediaName2">Languages: Kotlin</p>
            <p class="skillsMediaName2">Database Management: Cache</p>
        </div>

        <div className="skillsMediaDiv">
        <p class="skillsMediaName">React Native: Scare Game</p>
        <img className="skillsImg" src={sReactIcon} alt="Image" />
            <p class="skillsMediaName2">Purpose: Quick scare game to pull pranks</p>
            <p class="skillsMediaName2">Languages: JavaScript</p>
            <p class="skillsMediaName2">Library: React</p>
        </div>

        <div className="skillsMediaDiv">
        <p class="skillsMediaName">Flutter: To Do App</p>
        <img className="skillsImg" src={sFlutterIcon} alt="Image" />
        <p class="skillsMediaName2">Purpose: To learn about Flutter and acquire some experience</p>
        <p class="skillsMediaName2">Languages: Dart</p>
        <p class="skillsMediaName2">Software development kit: Flutter</p>
        </div>

        <div className="skillsMediaDiv">
            <p class="skillsMediaName">Java: Educational Game</p>
            <img className="skillsImg" src={sJavaIcon} alt="Image" />
            <p class="skillsMediaName2">Purpose: To create an educational app</p>
            <p class="skillsMediaName2">Languages: Java</p>
            <p class="skillsMediaName2">Frameworks: Android Studio</p>
        </div>

        <div className="skillsMediaDiv">
            <p class="skillsMediaName">Swift: Taxi Demo</p>
            <img className="skillsImg" src={sSwiftIcon} alt="Image" />
            <p class="skillsMediaName2">Purpose: For fun/Speed challenge</p>
            <p class="skillsMediaName2">Languages: Swift</p>
            <p class="skillsMediaName2">Frameworks: Xcode</p>
        </div>

        <div className="skillsMediaDiv">
            <p class="skillsMediaName">NoSql: Firebase Backend</p>
            <img className="skillsImg" src={sFirebaseIcon} alt="Image" />
            <p class="skillsMediaName2">Languages: Java, Swift</p>
            <p class="skillsMediaName2">Database Management: Firebase (No-Sql)</p>
            <p class="skillsMediaName2">Frameworks: Android Studio & Xcode</p>
        </div>

        <div className="skillsMediaDiv">
            <p class="skillsMediaName">C# (.NET): CRUD Operations</p>
            <img className="skillsImg" src={sCsharpIcon} alt="Image" />
            <p class="skillsMediaName2">Languages: C#, JavaScript (React)</p>
            <p class="skillsMediaName2">Database Management: MySql, Elastic, MySqlWorkBench</p>
            <p class="skillsMediaName2">Frameworks: Visual Studio & Visual Studio Code</p>
        </div>

        <div className="skillsMediaDiv">
            <p class="skillsMediaName">Python: Email Extraction</p>
            <img className="skillsImg" src={sCsharpIcon} alt="Image" />
            <p class="skillsMediaName2">Languages: Python</p>
            <p class="skillsMediaName2">Purpose: To automate email marketing</p>
            <p class="skillsMediaName2">Frameworks: Visual Studio Code</p>
        </div>

        <div className="skillsMediaDiv">
            <p class="skillsMediaName">Algorithm: Arithmetic-Sequence</p>
            <img className="skillsImg" src={sAlgorithmIcon} alt="Image" />
            <p class="skillsMediaName2">Languages: JavaScript</p>
            <p class="skillsMediaName2">Purpose: Creating an algorithmic project</p>
            <p class="skillsMediaName2">Frameworks: Visual Studio Code</p>
        </div>
    </div>
  );
}

export default MainMediaSkill;
