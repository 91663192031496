/** @format */

import React from "react";
import { Link, useNavigate } from "react-router-dom";

import {
  instaIcon,
  githubIcon,
  socirankIcon,
  googleIcon,
  appleIcon,
  youtubeIcon,
  facebookIcon2,
  pelegantIcon,
  aaGroupIcon,
} from "./../extension/img";
import {
  socirankText,
  bankText,
  foodText,
  hobbystarText,
  socirankWebText,
  collegeText,
  tutorText,
  pelegantText,
  tutorText2,
  aagroupText,
  raytailText,
} from "./../extension/about";
import {
  socirankExp,
  bankExp,
  hobbystarExp,
  foodExp,
  socirankWebExp,
  tutoringExp,
  collegeExp,
  pElegantExp,
  tutoring2Exp,
  aaGroupExp,
  raytail,
} from "./../extension/portfolioImgs";

export const ExpDesign = () => {
  return (
    <div>
      <div className="experienceDiv">
        <div className="experienceContainer">
          <div className="experienceRightDiv">
            <img className="experienceImg" src={socirankExp} alt="Image" />
            <div className="experienceIconDiv">
              <a href="https://socirank.com" target="_blank">
                <img className="icon" src={socirankIcon} alt="LinkedIn" />
              </a>
              <a href="https://instagram.com/socirank" target="_blank">
                <img className="icon" src={instaIcon} alt="Image" />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.socirank"
                target="_blank"
              >
                <img className="icon" src={googleIcon} alt="Image" />
              </a>
              <a
                href="https://apps.apple.com/us/app/socirank/id1628275604"
                target="_blank"
              >
                <img className="icon" src={appleIcon} alt="Image" />
              </a>
              <a
                href="https://github.com/Abu-Nabe/SociRankExtensions"
                target="_blank"
              >
                <img className="icon" src={githubIcon} alt="Image" />
              </a>
            </div>
          </div>

          <div className="experienceLeftDiv">
            <p className="experienceTitle">SociRank</p>
            <p className="experienceText">{socirankText}</p>
          </div>
        </div>

        <div className="experienceContainer">
          <div className="experienceLeftDiv">
            <p className="experienceTitle">Raytail</p>
            <p className="experienceText">{raytailText}</p>
          </div>
          <div className="experienceRightDiv">
            <img className="experienceImg2" src={raytail} alt="Image" />
          </div>
        </div>

        <div className="experienceContainer">
          <div className="experienceLeftDiv">
            <img className="experienceImg2" src={pElegantExp} alt="Image" />

            <div className="experienceIconDiv">
              <a href="https://pelegant.netlify.app/" target="_blank">
                <img className="icon" src={pelegantIcon} alt="Image" />
              </a>
              <a
                href="https://www.facebook.com/Kashmirartsemporuimofficial?mibextid=LQQJ4d"
                target="_blank"
              >
                <img className="icon" src={facebookIcon2} alt="Image" />
              </a>
              {/* <a href="https://github.com/Abu-Nabe/P.ELEGANT" target="_blank">
        <img className="icon" src={githubIcon} alt="Image" />
      </a> */}
            </div>
          </div>
          <div className="experienceRightDiv">
            <p className="experienceTitle">P.Elegant</p>
            <p className="experienceText">{pelegantText}</p>
          </div>
        </div>

        <div className="experienceContainer">
          <div className="experienceLeftDiv">
            <p className="experienceTitle">AAG Group Services</p>
            <p className="experienceText">{aagroupText}</p>
          </div>
          <div className="experienceRightDiv">
            <img className="experienceImg" src={aaGroupExp} alt="Image" />

            <div className="experienceIconDiv">
              <a href="https://aaglobalgroup.com.au/" target="_blank">
                <img className="icon" src={aaGroupIcon} alt="Image" />
              </a>
              <a
                href="https://www.youtube.com/watch?v=A7_T-Fk-jpM"
                target="_blank"
              >
                <img className="icon" src={youtubeIcon} alt="Image" />
              </a>
            </div>
          </div>
        </div>

        <div className="experienceContainer">
          <div className="experienceRightDiv">
            <img className="experienceImg2" src={bankExp} alt="Image" />
            <div className="experienceIconDiv">
              <a href="https://github.com/Abu-Nabe/banking" target="_blank">
                <img className="icon" src={githubIcon} alt="Image" />
              </a>
            </div>
          </div>
          <div className="experienceLeftDiv">
            <p className="experienceTitle">Personal Bank</p>
            <p className="experienceText">{bankText}</p>
          </div>
        </div>

        <div className="experienceContainer">
          <div className="experienceLeftDiv">
            <p className="experienceTitle">Food Scanner</p>
            <p className="experienceText">{foodText}</p>
          </div>
          <div className="experienceRightDiv">
            <img className="experienceImg" src={foodExp} alt="Image" />
            <div className="experienceIconDiv">
              <a
                href="https://www.youtube.com/watch?v=gALBM_urZr4"
                target="_blank"
              >
                <img className="icon" src={youtubeIcon} alt="Image" />
              </a>
              <a href="https://github.com/Abu-Nabe/foodscanner" target="_blank">
                <img className="icon" src={githubIcon} alt="Image" />
              </a>
            </div>
          </div>
        </div>

        <div className="experienceContainer">
          <div className="experienceRightDiv">
            <img className="experienceImg2" src={hobbystarExp} alt="Image" />
            <div className="experienceIconDiv">
              <a
                href="https://www.youtube.com/shorts/cgjstc8pins"
                target="_blank"
              >
                <img className="icon" src={youtubeIcon} alt="Image" />
              </a>
              <a href="https://github.com/Abu-Nabe/Hobbystar" target="_blank">
                <img className="icon" src={githubIcon} alt="Image" />
              </a>
            </div>
          </div>
          <div className="experienceLeftDiv">
            <p className="experienceTitle">HobbyStar</p>
            <p className="experienceText">{hobbystarText}</p>
          </div>
        </div>

        <div className="experienceContainer">
          <div className="experienceLeftDiv">
            <p className="experienceTitle">SociRank Website</p>
            <p className="experienceText">{socirankWebText}</p>
          </div>
          <div className="experienceRightDiv">
            <img className="experienceImg" src={socirankWebExp} alt="Image" />
            <div className="experienceIconDiv">
              <a href="https://socirank.com" target="_blank">
                <img className="icon" src={socirankIcon} alt="LinkedIn" />
              </a>
              <a
                href="https://www.youtube.com/watch?v=8CuML5eH288"
                target="_blank"
              >
                <img className="icon" src={youtubeIcon} alt="Image" />
              </a>
            </div>
          </div>
        </div>

        <div className="experienceContainer">
          <div className="experienceRightDiv">
            <img className="experienceImg2" src={collegeExp} alt="Image" />

            <div className="experienceIconDiv">
              <a href="https://github.com/Abu-Nabe/mybvp" target="_blank">
                <img className="icon" src={githubIcon} alt="Image" />
              </a>
            </div>
          </div>
          <div className="experienceLeftDiv">
            <p className="experienceTitle">College Attendance</p>
            <p className="experienceText">{collegeText}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExpDesign;
